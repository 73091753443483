<script lang="ts">
  import { Icon, Language } from "svelte-hero-icons";
  import { switchLanguage } from "../scripts/language";

  export let currentLanguage: string;
  export let languages: { locale: string; name: string }[];

  let visible = false;
  let container: HTMLDivElement;

  function onWindowClick(e: MouseEvent) {
    if (container.contains(e.target as any) === false) {
      visible = false;
    }
  }
</script>

<svelte:window on:click={onWindowClick} />

<div
  class="mt-2 mb-4 text-sm relative inline-block w-fit"
  id="language-menu"
  bind:this={container}
>
  <button
    type="button"
    class="flex flex-row gap-x-2 items-center"
    on:click|preventDefault={() => (visible = !visible)}
  >
    <Icon src={Language} class="w-5 h-5" />
    <div>
      {currentLanguage}
    </div>
  </button>

  {#if visible}
    <div
      class="absolute left-[-16px] bottom-8 z-10 mt-2 w-56 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
    >
      <div class="py-1" role="none">
        {#each languages as lang}
          <button
            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 w-full text-left"
            tabindex="-1"
            role="menuitem"
            on:click|preventDefault={() => switchLanguage(lang.locale)}
          >
            {lang.name}
          </button>
        {/each}
      </div>
    </div>
  {/if}
</div>
